import React, { useState, useEffect } from 'react';

const IframeComponent = () => {
  // State to manage popup visibility
  const [showPopup, setShowPopup] = useState(true); // Start with instructions visible

  // Function to handle iframe load
  const handleIframeLoad = () => {
    // If popup is visible, set a timeout to auto-close it after 35 seconds
    if (showPopup) {
      const autoCloseTimer = setTimeout(() => setShowPopup(false), 35000);
      // Cleanup function to clear timeout if the component unmounts
      return () => clearTimeout(autoCloseTimer);
    }
  };

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert('Copied to clipboard!'))
      .catch(err => alert('Failed to copy text'));
  };

  // Effect to handle auto-closing popup
  useEffect(() => {
    // Set a timeout to auto-close the popup after 35 seconds if it's visible
    const autoCloseTimer = setTimeout(() => setShowPopup(false), 35000);

    // Cleanup function to clear timeout if the component unmounts
    return () => clearTimeout(autoCloseTimer);
  }, []);

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100%', overflow: 'hidden' }}>
      {/* Popup Component */}
      {showPopup && (
        <div style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          background: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          padding: '20px',
          borderRadius: '5px',
          zIndex: 1000,
          maxWidth: '300px',
          fontSize: '14px',
          lineHeight: '1.5'
        }}>
          <strong>Instruction!</strong>
          <div style={{ marginTop: '10px' }}>
            <strong>Step 1</strong>
            <p>
              Go to settings then select "Streaming".
              <br />
              In the Url, click the gear icon to edit URL and paste the stream URL below:
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                readOnly
                value="https://stream.faithcloud.net"
                style={{ flex: 1, marginRight: '10px' }}
              />
              <button onClick={() => copyToClipboard('https://stream.faithcloud.net')}>
                Copy
              </button>
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <strong>Step 2</strong>
            <p>
              Go to Addons or the puzzle icon and click the plus(+) or Add addons button.
              <br />
              Paste the addons URL below:
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                readOnly
                value="https://torrentio.strem.fun/sizefilter=2GB/manifest.json"
                style={{ flex: 1, marginRight: '10px' }}
              />
              <button onClick={() => copyToClipboard('https://torrentio.strem.fun/sizefilter=2GB/manifest.json')}>
                Copy
              </button>
            </div>
          </div>
          <button 
            onClick={() => setShowPopup(false)} 
            style={{ marginTop: '10px', background: 'white', color: 'black' }}
          >
            Close
          </button>
        </div>
      )}
      {/* Hover Button */}
      <button
        onClick={() => setShowPopup(prev => !prev)}
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          background: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          border: 'none',
          padding: '10px',
          borderRadius: '50%',
          cursor: 'pointer',
          zIndex: 1001,
          fontSize: '20px',
          display: showPopup ? 'none' : 'block' // Hide button if popup is visible
        }}
      >
        {showPopup ? '×' : 'ℹ️'}
      </button>
      <iframe
        src="https://web.stremio.com"
        title="Faith Cloud | To Play Movies"
        style={{ width: '100%', height: '100%', border: 'none' }}
        allowFullScreen
        onLoad={handleIframeLoad} // Handle the iframe load event
      />
    </div>
  );
};

export default IframeComponent;
